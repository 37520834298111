import { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Dropdown, Nav, Navbar } from "react-bootstrap";
import { CgMenu } from "react-icons/cg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  applogo,
  employee_light,
  employee_primary,
  finances_light,
  finances_primary,
} from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../actions/AuthActions";
import { LuLayoutDashboard } from "react-icons/lu";
import { RiTeamLine } from "react-icons/ri";
import { MdOutlineRocketLaunch } from "react-icons/md";
import { AiTwotoneShop } from "react-icons/ai";
import { FaRegFileAlt } from "react-icons/fa";
import { customAction } from "../../actions/AuthActions";
import { truncateString } from "../../utils";

const smallDeviceRoutes = [
  { title: "Dashboard", route: "", icon: <LuLayoutDashboard size={20} /> },
  {
    title: "Employee Management",
    route: "employeemanagement",
    icon: <img src={employee_primary} alt="" className="p-1" />,
    lightIcon: <img src={employee_light} alt="" className="p-1" />,
  },
  {
    title: "Team Management",
    route: "teammanagement",
    icon: <img src={employee_primary} alt="" className="p-1" />,
    lightIcon: <img src={employee_light} alt="" className="p-1" />,
  },
  // {
  //   title: "Leaves",
  //   route: "leaves",
  //   icon: <img src={employee_primary} alt="" className="p-1" />,
  //   lightIcon: <img src={employee_light} alt="" className="p-1" />,
  // },
  {
    title: "Clients",
    route: "clients",
    icon: <RiTeamLine size={18} />,
  },
  {
    title: "Projects",
    route: "projects",
    icon: <MdOutlineRocketLaunch size={20} />,
  },
  {
    title: "Vendors",
    route: "vendors",
    icon: <AiTwotoneShop size={20} />,
  },
  {
    title: "Project - Reports",
    route: "reports/project",
    icon: <FaRegFileAlt size={15} />,
  },
  {
    title: "Payments",
    route: "reports/payments",
    icon: <AiTwotoneShop size={20} />,
  },
  {
    title: "Invoices",
    route: "invoices",
    icon: <img src={finances_primary} alt="" />,
    lightIcon: <img src={finances_light} alt="" />,
  },
  {
    title: "Expenses",
    route: "expenses",
    icon: <img src={finances_primary} alt="" />,
    lightIcon: <img src={finances_light} alt="" />,
  },
  {
    title: "Salary Sheet",
    route: "salary",
    icon: <img src={finances_primary} alt="" />,
    lightIcon: <img src={finances_light} alt="" />,
  },
  {
    title: `My Account`,
    route: "account",
    icon: "",
  },
  {
    title: "Bank Accounts",
    route: "bank-accounts",
    icon: "",
  },
  {
    title: "Log Out",
    route: "logout",
    icon: "",
  },
];

const employeePages = [
  {
    title: "Dashboard",
    route: "",
    icon: <LuLayoutDashboard size={20} />,
  },
  {
    title: "Projects",
    route: "projects",
    icon: <MdOutlineRocketLaunch size={20} />,
  },
  {
    title: "Leaves",
    route: "leaves",
    icon: <img src={employee_primary} alt="" className="p-1" />,
    lightIcon: <img src={employee_light} alt="" className="p-1" />,
  },
  {
    title: "Timesheet",
    route: "timesheet",
    icon: <img src={employee_primary} alt="" className="p-1" />,
    lightIcon: <img src={employee_light} alt="" className="p-1" />,
  },
];

const NavLink = ({ item, onClick, handleLogout, onNavigate }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = location?.pathname?.split("/").filter((i) => i !== "");
  const param1 = params?.length > 0 ? params[0] : "";

  const [show, setShow] = useState(false);
  const dropdownRef = useRef();

  const handleToggle = () => {
    setShow(!show);
  };

  const handleNavigate = (route) => {
    if (route === "logout") {
      handleLogout();
    } else {
      navigate(route);
      setShow(false);
    }
    onNavigate();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <Nav.Link
      className={`${
        param1 === item?.route ? "text-primary" : "text-tertiary"
      } d-flex align-items-center gap-1 text-center ps-2 pe-3 py-2`}
      onClick={item?.hasDropdown ? null : onClick}
      style={{
        borderBottom:
          (param1 === item?.route ||
            (item?.hasDropdown &&
              item?.options?.filter((i) => i.route === param1).length > 0)) &&
          "3px solid #116C96",
      }}
    >
      {item?.hasDropdown ? (
        <Dropdown
          show={show}
          ref={dropdownRef}
          as={ButtonGroup}
          className="d-flex p-0 m-0 focus-none br-10"
        >
          <Button
            onClick={handleToggle}
            variant="link"
            className={` ${
              param1 === item?.route ||
              (item?.hasDropdown &&
                item?.options?.filter((i) => i.route === param1).length > 0)
                ? "text-===="
                : "text-tertiary"
            } bg-transparent text-decoration-none fs-16 fw-500 p-0`}
          >
            {item?.lightIcon
              ? param1 === item?.route ||
                (item?.hasDropdown &&
                  item?.options?.filter((i) => i.route === param1).length > 0)
                ? item?.icon
                : item?.lightIcon
              : item?.icon}
            {item?.title}
            {item?.count ? (
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {item?.count}
              </span>
            ) : (
              ""
            )}
          </Button>
          <Dropdown.Toggle
            split
            onClick={handleToggle}
            variant="link"
            id="dropdown-split-basic"
            className="bg-transparent text-black focus-none border-0 py-0"
            style={{ boxShadow: "none" }}
          />
          {item?.options?.length > 0 && (
            <>
              <Dropdown.Menu
                className="focus-none card-box-shadow"
                style={{
                  borderLeft: "0.5px solid #dee2e6",
                  borderRight: "0.5px solid #dee2e6",
                  borderBottom: "0.5px solid #dee2e6",
                  borderTop: "none",
                }}
              >
                {item?.options?.map((option, index) => (
                  <Dropdown.Item
                    key={index}
                    className="fs-15 fw-400 my-1 d-flex justify-content-between"
                    onClick={() => {
                      if (option?.route === "logout") {
                        handleLogout();
                      } else {
                        handleNavigate(`/${option?.route}`);
                      }
                    }}
                  >
                    <span>{option?.title}</span>
                    {option?.count ? (
                      <span className="px-2 text-white br-50 bg-danger">
                        {option?.count}
                      </span>
                    ) : (
                      ""
                    )}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </>
          )}
        </Dropdown>
      ) : (
        <>
          <span className="h-100 fs-16 fw-500">
            {item?.lightIcon
              ? param1 === item?.route ||
                (item?.hasDropdown &&
                  item?.options?.filter((i) => i.route === param1).length > 0)
                ? item?.icon
                : item?.lightIcon
              : item?.icon}{" "}
            {item?.title}
          </span>
        </>
      )}
    </Nav.Link>
  );
};

const NavBar = () => {
  const dispatch = useDispatch();
  const { user, token, refresh, leaveCount } = useSelector(
    (state) => state.authReducer
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const large = screenWidth > 992;

  const pMPages = [
    {
      title: "Dashboard",
      route: "",
      icon: <LuLayoutDashboard size={20} />,
    },
    {
      title: "Employees",
      route: "employees",
      icon: <img src={employee_primary} alt="" className="p-1" />,
      lightIcon: <img src={employee_light} alt="" className="p-1" />,
      count: leaveCount,
      hasDropdown: true,
      options: [
        { title: "Employee Management", route: "employeemanagement" },
        { title: "Timesheets", route: "timesheet" },
        { title: "Team Management", route: "teammanagement" },
        { title: "Leave Type Management", route: "leave-type" },
        { title: "Leaves", route: "leaves", count: leaveCount },
      ],
    },
    {
      title: "Clients",
      route: "clients",
      icon: <RiTeamLine size={18} />,
    },
    {
      title: "Projects",
      route: "projects",
      icon: <MdOutlineRocketLaunch size={20} />,
    },
    {
      title: "Vendors",
      route: "vendors",
      icon: <AiTwotoneShop size={20} />,
    },
  ];

  const adminPages = [
    {
      title: "Dashboard",
      route: "",
      icon: <LuLayoutDashboard size={20} />,
    },
    {
      title: "Employees",
      route: "employees",
      icon: <img src={employee_primary} alt="" className="p-1" />,
      lightIcon: <img src={employee_light} alt="" className="p-1" />,
      hasDropdown: true,
      count: leaveCount,
      options: [
        { title: "Employee Management", route: "employeemanagement" },
        { title: "Timesheets", route: "timesheet" },
        { title: "Team Management", route: "teammanagement" },
        { title: "Role Management", route: "rolemanagement" },
        { title: "Leave Policy Management", route: "leave-policy" },
        { title: "Leave Type Management", route: "leave-type" },
        { title: "Leaves", route: "leaves", count: leaveCount },
      ],
    },
    {
      title: "Clients",
      route: "clients",
      icon: <RiTeamLine size={18} />,
    },
    {
      title: "Projects",
      route: "projects",
      icon: <MdOutlineRocketLaunch size={20} />,
    },
    {
      title: "Vendors",
      route: "vendors",
      icon: <AiTwotoneShop size={20} />,
    },
    {
      title: "Reports",
      route: "reports",
      icon: <FaRegFileAlt size={15} />,
      hasDropdown: true,
      options: [
        { title: "Projects", route: "reports/project" },
        { title: "Payments", route: "reports/payments" },
      ],
    },
    {
      title: "Finance",
      route: "finance",
      icon: <img src={finances_primary} alt="" />,
      lightIcon: <img src={finances_light} alt="" />,
      hasDropdown: true,
      options: [
        { title: "Invoices", route: "invoices" },
        { title: "Recurring Invoices", route: "recurring_invoices" },
        { title: "Expenses", route: "expenses" },
        { title: "Salary Sheet", route: "salary" },
      ],
    },
  ];

  const handleToggleNavbar = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const handleNavigate = (path) => {
    if (path === "/logout") {
      handleLogout();
    } else {
      navigate(path);
    }
    setIsNavbarCollapsed(true);
  };

  const userOptions = [
    { title: "My Account", route: "account" },
    ...(user?.is_admin
      ? [{ title: "Bank Accounts", route: "bank-accounts" }]
      : []),
    { title: "Log Out", route: "logout" },
  ];

  const pages = [
    ...(user?.is_admin
      ? adminPages
      : user?.is_project_manager
      ? pMPages
      : employeePages),
    {
      title: `${truncateString(user?.name, 10)}`,
      route: "account",
      icon: "",
      hasDropdown: true,
      options: userOptions,
    },
  ];

  const fetchLeaveCount = async () => {
    const resp = await customAction(
      `leave/pending-leave-count`,
      "fetch",
      null,
      token
    );
    if (resp) {
      dispatch(authActions.updateLeaveCount(resp.pending_leave_count));
    }
  };

  useEffect(() => {
    if (user?.is_project_manager || user?.is_admin) {
      fetchLeaveCount();
    }
  }, [location]);

  const handleLogout = async () => {
    const data = { refresh_token: refresh };
    const response = await customAction("user/logout", "post", data, token);
    if (response) {
      dispatch(authActions.logout());
      navigate("/");
    }
  };
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    fetchLeaveCount();
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Navbar
      expand="lg"
      fixed="top"
      className={`${screenWidth > 991.9 ? "bg-white" : ""} p-0`}
      bg={"white"}
      style={{ border: "1px solid #E8EAED" }}
    >
      <Navbar.Brand
        onClick={() => navigate("/")}
        className="d-flex align-items-center c-p"
      >
        <img
          src={applogo}
          alt=""
          className="img-fluid mx-2"
          style={{ width: 150, height: 40 }}
        />
      </Navbar.Brand>
      <Navbar.Toggle
        onClick={handleToggleNavbar}
        className="focus-none p-1 border-0 focus-none border-0"
      >
        <CgMenu size={24} />
      </Navbar.Toggle>

      <Navbar.Collapse
        in={!isNavbarCollapsed}
        className={
          large
            ? "d-flex justify-content-end p-1"
            : isNavbarCollapsed
            ? "d-none"
            : "bg-white mx-2"
        }
        style={{ borderRadius: 10 }}
      >
        <div className="d-flex justify-content-center align-items-center ">
          <Nav
            className={`fs-14 fw-600 gap-1 pe-5 scrollbar-none ${
              large ? "ms-auto my-2 my-lg-0" : "my-2 my-lg-0"
            }`}
            navbarScroll
          >
            {screenWidth >= 768 &&
              pages?.map((item, index) => {
                return (
                  <div className="d-flex align-items-center" key={index}>
                    <NavLink
                      item={item}
                      onClick={() => handleNavigate(`/${item?.route}`)}
                      handleLogout={handleLogout}
                      onNavigate={() => setIsNavbarCollapsed(true)}
                    />
                  </div>
                );
              })}
            {screenWidth < 768 &&
              smallDeviceRoutes?.map((item, index) => {
                return (
                  <div className="d-flex align-items-center" key={index}>
                    <NavLink
                      item={item}
                      onClick={() => handleNavigate(`/${item?.route}`)}
                      handleLogout={handleLogout}
                      onNavigate={() => setIsNavbarCollapsed(true)}
                    />
                  </div>
                );
              })}
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
